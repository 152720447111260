
import React, { Component } from "react";
import 'date-fns';
import { Navbar,Nav,Table} from "react-bootstrap";
//import { Particles } from './Original.js';
import nlLocale from 'date-fns/locale/nl';
import enLocale from 'date-fns/locale/en-US';
import TextField from '@material-ui/core/TextField';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import * as moment  from 'moment';
import "moment/locale/be";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


var zhr; var whr;
const localeMap = {
  en: enLocale,
  nl: nlLocale,
};
const maskMap = {
  nl: '__/__/____',
  en: '__/__/____',
  ru: '__.__.____',
  de: '__.__.____',
};

class App extends Component {
  render() {
    return (
      <Router>
        <Navbar  bg="dark" variant="dark" expand="lg" sticky="top" >
            <Navbar.Brand href="#home">
             <img width="250px" height="auto" className="img-responsive" src='logo.jpg'  alt="logo" />
            </Navbar.Brand>
            
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="nav-text">
                <Nav.Link href="/">Partikels</Nav.Link>
                <Nav.Link href="/massa">Massa</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <br />
        <div>
          <Switch>
          <Route path="/massa">
              <Massa />
            </Route>
            <Route path="/">
              <Home />
            </Route>
            
          </Switch>
        </div>
      </Router>
    );
  }
}


class Massa extends Component {
  
  constructor(props) {
    super(props);
    this.state = {start: new Date()};
    
    this.state = {
      pressure: "..."
    };
    this.state = {
      localeVar: nlLocale
    };
    this.datePickerRequest = this.datePickerRequest.bind(this)
    this.state = {
      dylos: [],
    };
    this.dylosRequest = this.dylosRequest.bind(this)
    this.state = {
      dylos: [],
    };
    this.setDateValue = this.setDateValue.bind(this)
    
  }

  componentDidMount() {
    document.title = "Fijnstof App";
    whr = new XMLHttpRequest();
    whr.open("GET","https://dev.winsys.be:443/api/GetAtmosphericValues", true);
    whr.send();
    whr.addEventListener("readystatechange", this.dylosRequest, false);
  }
 
 datePickerRequest() {
    if (zhr.readyState === 4 && zhr.status === 200) {
      var response = JSON.parse(zhr.response);
      this.setState({dylos: response});
    }
  }
  dylosRequest() {
    if (whr.readyState === 4 && whr.status === 200) {
      var response = JSON.parse(whr.response);
      this.setState({dylos: response});
    } 
  }
  setDateValue(selDate) {
    //Tue Aug 03 2021 22:56:57 GMT+0200 
   const selectedShowDate = moment(selDate).format('MM-DD-YYYY');
   this.setState({start: selectedShowDate});
   const selectedDate = moment(selDate).format('YYYY-MM-DD');
   zhr = new XMLHttpRequest();
   zhr.open("GET","https://dev.winsys.be:443/api/GetDayValues?date=" + selectedDate, true);
   zhr.send();
   zhr.addEventListener("readystatechange", this.datePickerRequest, false);
  }


  render() {
    return (

      <div>
        <h2> Luchtkwaliteit Kasterlee {this.state.nu}</h2>
        <hr></hr>
        <h8>&#160;<b>Locatie:</b> Grootreesdijk</h8>&#160;<h8>&#160;<b>Meettoestel:</b> Dylos DC1700</h8><br/>
        <h8>&#160;<b>PM2.5:</b>&#160;<img width="15px" height="auto" className="img-responsive" src='greenbullet.jpg'  alt="green" /> &lt;10 <img width="15px" height="auto" className="img-responsive" src="lightgreenbullet.jpg"  alt="lightgreen" /> &lt;20 <img width="15px" height="auto" className="img-responsive" src='yellowbullet.jpg'  alt="yellow" /> &lt;30 <img width="15px" height="auto" className="img-responsive" src='lightredbullet.jpg'  alt="lightred" /> &lt;40 &#160;<img width="15px" height="auto" className="img-responsive" src='redbullet.jpg'  alt="red" /> &gt;45</h8>
        <br/>
        <h8>&#160;<b>PM10:</b>&#160;&#160;<img width="15px" height="auto" className="img-responsive" src='greenbullet.jpg'  alt="green" /> &lt;10 <img width="15px" height="auto" className="img-responsive" src="lightgreenbullet.jpg"  alt="lightgreen" /> &lt;30 <img width="15px" height="auto" className="img-responsive" src='yellowbullet.jpg'  alt="yellow" /> &lt;45 <img width="15px" height="auto" className="img-responsive" src='lightredbullet.jpg'  alt="lightred" /> &lt;65 &#160;<img width="15px" height="auto" className="img-responsive" src='redbullet.jpg'  alt="red" /> &gt;65</h8>
        <br/>
        <br/>
        <div>
          &#160;
          <LocalizationProvider dateAdapter={AdapterDateFns} locale = {localeMap['nl']}>
          <DatePicker
            label="Kies Datum:"
            value={this.state.start}
            mask={maskMap['nl']}
            onChange={(newValue) => {
              this.setDateValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          </LocalizationProvider>
        <br/><br/>
        </div>
        <div>
          <Table light>
              <NumberList cpeconst = {this.state.dylos}/> 
          </Table>
            
        </div>
      </div>
     );
  }
};

class  NumberList extends Component {
  render() {
  const listcpe = this.props.cpeconst.map((cpe) => 
  <tr>
  <td><img src={process.env.PUBLIC_URL + cpe[0]}/></td>
  <td> {cpe[2]}</td>
  <td> {cpe[1]}</td>
  <td>{cpe[6]}</td>
  <td>{cpe[3]}</td>
  <td>{cpe[7]}</td>
  <td>{cpe[8]}</td>
  <td>{cpe[4]}</td>
  <td>{cpe[5]}</td>
  <td>{cpe[9]}</td>
  </tr> 
  );
    return(
      <tbody>
      <tr><th>Code</th><th>PM2.5  &micro;g/&#13221;</th><th>PM10  &micro;g/&#13221;</th><th>Tijd-stip</th><th>Hum. %</th><th>Wind</th><th>Km/u</th><th>Temp&#8451;</th><th>Lucht hPa</th><th>Weerbeschrijving</th></tr>
      {listcpe}
      </tbody>
    );
  }
}


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {start: new Date()};
    
    this.state = {
      pressure: "..."
    };
    this.state = {
      localeVar: nlLocale
    };
    this.datePickerRequest = this.datePickerRequest.bind(this)
    this.state = {
      dylos: [],
    };
    this.dylosRequest = this.dylosRequest.bind(this)
    this.state = {
      dylos: [],
    };
    this.setDateValue = this.setDateValue.bind(this)
    
  }

  componentDidMount() {
    document.title = "Fijnstof App Partikels";
    whr = new XMLHttpRequest();
    whr.open("GET","https://dev.winsys.be:443/api/GetAtmosphericParticleValues", true);
    whr.send();
    whr.addEventListener("readystatechange", this.dylosRequest, false);
  }
 
 datePickerRequest() {
    if (zhr.readyState === 4 && zhr.status === 200) {
      var response = JSON.parse(zhr.response);
      this.setState({dylos: response});
    }
  }
  dylosRequest() {
    if (whr.readyState === 4 && whr.status === 200) {
      var response = JSON.parse(whr.response);
      this.setState({dylos: response});
    } 
  }
  setDateValue(selDate) {
    //Tue Aug 03 2021 22:56:57 GMT+0200 
   const selectedShowDate = moment(selDate).format('MM-DD-YYYY');
   this.setState({start: selectedShowDate});
   const selectedDate = moment(selDate).format('YYYY-MM-DD');
   zhr = new XMLHttpRequest();
   zhr.open("GET","https://dev.winsys.be:443/api/GetDayParticleValues?date=" + selectedDate, true);
   zhr.send();
   zhr.addEventListener("readystatechange", this.datePickerRequest, false);
  }


  render() {
    return (

      <div>
        <h2> Luchtkwaliteit Kasterlee {this.state.nu}</h2>
        <hr></hr>
        <h8>&#160;<b>Eenheid:</b> Particles/ 0.01 Cubic Foot</h8><br/>
        <h8>&#160;<b>Locatie:</b> Grootreesdijk</h8>&#160;<h8>&#160;<b>Meettoestel:</b> Dylos DC1700</h8><br/>
        <h8>&#160;<b>PM2.5:</b>&#160;<img width="15px" height="auto" className="img-responsive" src='greenbullet.jpg'  alt="green" /> &lt;1000 <img width="15px" height="auto" className="img-responsive" src="lightgreenbullet.jpg"  alt="lightgreen" /> &lt;3000 <img width="15px" height="auto" className="img-responsive" src='yellowbullet.jpg'  alt="yellow" /> &lt;5000 <img width="15px" height="auto" className="img-responsive" src='lightredbullet.jpg'  alt="lightred" /> &lt;7000 &#160;<img width="15px" height="auto" className="img-responsive" src='redbullet.jpg'  alt="red" /> &#160;&gt;7000</h8>
        <br/>
        <br/>
        <div>
          &#160;
          <LocalizationProvider dateAdapter={AdapterDateFns} locale = {localeMap['nl']}>
          <DatePicker
            label="Kies Datum:"
            value={this.state.start}
            mask={maskMap['nl']}
            onChange={(newValue) => {
              this.setDateValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          </LocalizationProvider>
        <br/><br/>
        </div>
        <div>
          <Table>
              <NumberParticleList cpeconst = {this.state.dylos}/> 
          </Table>
            
        </div>
      </div>
     );
  }
};

class  NumberParticleList extends Component {
  render() {
  const listcpe = this.props.cpeconst.map((cpe) => 
  <tr>
  <td><img width="15px" height="auto" src={process.env.PUBLIC_URL + cpe[0]}/></td>
  <td> {cpe[2]}</td>
  <td> {cpe[1]}</td>
  <td>{cpe[6]}</td>
  <td>{cpe[3]}</td>
  <td>{cpe[7]}</td>
  <td>{cpe[8]}</td>
  <td>{cpe[4]}</td>
  <td>{cpe[5]}</td>
  <td>{cpe[9]}</td>
  </tr> 
  );
    return(
      <tbody>
      <tr><th light>Code</th><th>PM2.5 </th><th>PM10 </th><th>Tijd-stip</th><th>Hum. %</th><th>Wind</th><th>Km/u</th><th>Temp&#8451;</th><th>Lucht hPa</th><th>Weerbeschrijving</th></tr>
      {listcpe}
      </tbody>
    );
  }
}

export default App;
